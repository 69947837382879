import React from 'react';
import toolState from '../store/toolState';
import canvasState from '../store/canvasState';
import Brush from '../tools/Brush';
import Erase from '../tools/Erase';


function Toolbar() {
    return (
        <div className='toolbar'>
            <button className='btn' onClick={() => toolState.setTool(new Brush(canvasState.canvas, canvasState.socket))}>Brush</button>
            <button className='btn' onClick={() => toolState.setTool(new Erase(canvasState.canvas, canvasState.socket))}>Erase</button>
        </div>
    )
}

export default Toolbar;