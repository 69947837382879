import { makeAutoObservable } from "mobx";

class CanvasState {
    canvas = null
    username = ""
    socket = null
    constructor() {
        makeAutoObservable(this);
    }

    setUsername(username){
        this.username = username;
    }

    setSocket(socket) {
        this.socket = socket;
    }

    setCanvas(canvas) {
        this.canvas = canvas;
    }

}

export default new CanvasState()