import canvasState from "../store/canvasState";
import Tool from "./Tool";
import toolState from "../store/toolState";

export default class Brush extends Tool {
    constructor(canvas, socket) {
        super(canvas, socket);
        this.listen()
    }

    listen() {
        this.canvas.onmousemove = this.mouseMoveHandler.bind(this)
        this.canvas.onmousedown = this.mouseDownHandler.bind(this)
        this.canvas.onmouseup = this.mouseUpHandler.bind(this) 
    }

    mouseUpHandler(e) {
        this.mouseDown = false
        this.socket.send(JSON.stringify({
            method: 'draw',
            figure: {
                type: 'finish',
                
            }
        }))
        
        const dataURL = canvasState.canvas.toDataURL();
        this.socket.send(JSON.stringify({method:'saveCanvas', data: dataURL}))
    }

    mouseDownHandler(e) {
        this.mouseDown = true
        this.ctx.beginPath()
        this.ctx.moveTo(e.pageX - e.target.offsetLeft, e.pageY - e.target.offsetTop)
    }

    mouseMoveHandler(e) {
        if( this.mouseDown) {
            this.socket.send(JSON.stringify({
                method: 'draw',
                figure: {
                    type: 'brush',
                    color: `${toolState.color}`,
                    width: `${toolState.width}`,
                    x: e.pageX - e.target.offsetLeft,
                    y: e.pageY - e.target.offsetTop,
                }
            }))
        }
    }

    static draw(ctx, width, color, x, y) {
        ctx.lineWidth = width 
        ctx.strokeStyle = color;
        ctx.lineTo(x, y);
        ctx.stroke();
    }
}